import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        const ids = [];

        // Query all input elements of type checkbox within the structure
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');

        // Loop through each checkbox and extract its id attribute
        checkboxes.forEach((checkbox) => {
            ids.push(checkbox.id);
        });

        const checked = []
        ids.forEach(id => {
            const result = localStorage.getItem(id) || false;
            console.log(`${id} = ${result}`)
            if(result === "true") {
                console.log("push");
                checked.push(id)
            }
        })

        checked.forEach(id => {
            document.querySelector(`#${id}`).checked = true
        })

        console.log("THE CHECKED ARRAY");
        console.log(checked);
        setCheckedItems(checked)
    }, [])

    return (
        <div className="container mx-auto">
        <h1 className="text-3xl font-bold mb-6">Maurice hat dich Lieb ♥️</h1>

        <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Frühstück</h2>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2">Tofu Scramble</td>
        <td className="border px-4 py-2">300 kcal</td>
        <td className="border px-4 py-2">17g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Haferflocken mit Quark und Obst</td>
        <td className="border px-4 py-2">390 kcal</td>
        <td className="border px-4 py-2">19g</td>
        </tr>
        </tbody>
        </table>
        </div>
        <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Mittagessen</h2>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2">Curry mit Kichererbsen-Tomatensoße und Reis</td>
        <td className="border px-4 py-2">530 kcal</td>
        <td className="border px-4 py-2">14g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Tofu mit Kohl und Sahnesoße und Kartoffeln</td>
        <td className="border px-4 py-2">650 kcal</td>
        <td className="border px-4 py-2">24g</td>
        </tr>
        </tbody>
        </table>
        </div>
        <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Abendessen</h2>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2">Ofengemüse mit Dip und Like Chicken</td>
        <td className="border px-4 py-2">570 kcal</td>
        <td className="border px-4 py-2">25g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Hummus Wraps mit Chicken und Sour Cream</td>
        <td className="border px-4 py-2">560 kcal</td>
        <td className="border px-4 py-2">35g</td>
        </tr>
        </tbody>
        </table>
        </div>







        <div className="container mx-auto bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Zusammensetzung der Gerichte</h2>

        <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Frühstück</h3>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Zutat</th>
        <th className="px-4 py-2">Menge</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2" rowSpan="4">Tofu Scramble</td>
        <td className="border px-4 py-2">Tofu</td>
        <td className="border px-4 py-2">150g</td>
        <td className="border px-4 py-2">140 kcal</td>
        <td className="border px-4 py-2">15g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Gemüse (Paprika, Zwiebel, Spinat)</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">30 kcal</td>
        <td className="border px-4 py-2">2g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Öl</td>
        <td className="border px-4 py-2">15ml</td>
        <td className="border px-4 py-2">120 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Gewürze</td>
        <td className="border px-4 py-2">nach Geschmack</td>
        <td className="border px-4 py-2">10 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2" rowSpan="4">Haferflocken mit Quark und Obst</td>
        <td className="border px-4 py-2">Haferflocken</td>
        <td className="border px-4 py-2">50g</td>
        <td className="border px-4 py-2">190 kcal</td>
        <td className="border px-4 py-2">6g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Quark</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">70 kcal</td>
        <td className="border px-4 py-2">12g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Obst (Banane/Beeren)</td>
        <td className="border px-4 py-2">150g</td>
        <td className="border px-4 py-2">100 kcal</td>
        <td className="border px-4 py-2">1g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Agavendicksaft</td>
        <td className="border px-4 py-2">10g</td>
        <td className="border px-4 py-2">30 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        </tbody>
        </table>
        </div>

        <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Mittagessen</h3>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Zutat</th>
        <th className="px-4 py-2">Menge</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2" rowSpan="4">Curry mit Kichererbsen-Tomatensoße und Reis</td>
        <td className="border px-4 py-2">Kichererbsen</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">160 kcal</td>
        <td className="border px-4 py-2">8g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Tomatensoße</td>
        <td className="border px-4 py-2">200g</td>
        <td className="border px-4 py-2">50 kcal</td>
        <td className="border px-4 py-2">2g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Reis</td>
        <td className="border px-4 py-2">150g gekocht</td>
        <td className="border px-4 py-2">200 kcal</td>
        <td className="border px-4 py-2">4g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Öl</td>
        <td className="border px-4 py-2">15ml</td>
        <td className="border px-4 py-2">120 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2" rowSpan="5">Tofu mit Kohl und Sahnesoße und Kartoffeln</td>
        <td className="border px-4 py-2">Tofu</td>
        <td className="border px-4 py-2">150g</td>
        <td className="border px-4 py-2">140 kcal</td>
        <td className="border px-4 py-2">15g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Kohl</td>
        <td className="border px-4 py-2">200g</td>
        <td className="border px-4 py-2">50 kcal</td>
        <td className="border px-4 py-2">2g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Sahnesoße</td>
        <td className="border px-4 py-2">100ml</td>
        <td className="border px-4 py-2">200 kcal</td>
        <td className="border px-4 py-2">3g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Kartoffeln</td>
        <td className="border px-4 py-2">200g</td>
        <td className="border px-4 py-2">140 kcal</td>
        <td className="border px-4 py-2">4g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Öl</td>
        <td className="border px-4 py-2">15ml</td>
        <td className="border px-4 py-2">120 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        </tbody>
        </table>
        </div>

        <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Abendessen</h3>
        <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-200">
        <tr>
        <th className="px-4 py-2">Gericht</th>
        <th className="px-4 py-2">Zutat</th>
        <th className="px-4 py-2">Menge</th>
        <th className="px-4 py-2">Kalorien</th>
        <th className="px-4 py-2">Protein</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="border px-4 py-2" rowSpan="4">Ofengemüse mit Dip und Like Chicken</td>
        <td className="border px-4 py-2">Gemüse (Paprika, Zucchini, Karotten)</td>
        <td className="border px-4 py-2">300g</td>
        <td className="border px-4 py-2">80 kcal</td>
        <td className="border px-4 py-2">2g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Öl</td>
        <td className="border px-4 py-2">30ml</td>
        <td className="border px-4 py-2">240 kcal</td>
        <td className="border px-4 py-2">0g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Like Chicken</td>
        <td className="border px-4 py-2">150g</td>
        <td className="border px-4 py-2">200 kcal</td>
        <td className="border px-4 py-2">20g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Dip (Joghurt, Kräuter)</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">50 kcal</td>
        <td className="border px-4 py-2">3g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2" rowSpan="5">Hummus Wraps mit Chicken und Sour Cream</td>
        <td className="border px-4 py-2">Wrap</td>
        <td className="border px-4 py-2">50g</td>
        <td className="border px-4 py-2">150 kcal</td>
        <td className="border px-4 py-2">4g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Hummus</td>
        <td className="border px-4 py-2">50g</td>
        <td className="border px-4 py-2">150 kcal</td>
        <td className="border px-4 py-2">4g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Chicken</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">180 kcal</td>
        <td className="border px-4 py-2">25g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Sour Cream</td>
        <td className="border px-4 py-2">30g</td>
        <td className="border px-4 py-2">60 kcal</td>
        <td className="border px-4 py-2">1g</td>
        </tr>
        <tr>
        <td className="border px-4 py-2">Gemüse (Salat, Tomate)</td>
        <td className="border px-4 py-2">100g</td>
        <td className="border px-4 py-2">20 kcal</td>
        <td className="border px-4 py-2">1g</td>
        </tr>
        </tbody>
        </table>
        </div>

        </div>

        <div className="mb-8 mt-8">
        <h2 className="text-2xl font-semibold mb-4">Essensplan für Maurice</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-2">Tag 1, 3, 5</h3>
        <ul className="list-disc pl-5">
        <li>Frühstück: Haferflocken mit Quark und Obst (390 kcal, 19g Protein)</li>
        <li>Mittagessen: Tofu mit Kohl und Sahnesoße und Kartoffeln (650 kcal, 24g Protein)</li>
        <li>Abendessen: Hummus Wraps mit Chicken und Sour Cream (560 kcal, 35g Protein)</li>
        <li>Offene Kalorien: 700 kcal</li>
        <li>Gesamt: 2400 kcal, 78g Protein</li>
        </ul>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-2">Tag 2, 4, 6</h3>
        <ul className="list-disc pl-5">
        <li>Frühstück: Tofu Scramble (300 kcal, 17g Protein)</li>
        <li>Mittagessen: Curry mit Kichererbsen-Tomatensoße und Reis (530 kcal, 14g Protein)</li>
        <li>Abendessen: Ofengemüse mit Dip und Like Chicken (570 kcal, 25g Protein)</li>
        <li>Offene Kalorien: 700 kcal</li>
        <li>Gesamt: 2400 kcal, 56g Protein</li>
        </ul>
        </div>
        </div>
        </div>

        <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Essensplan für Shirin</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-2">Tag 1, 3, 5</h3>
        <ul className="list-disc pl-5">
        <li>Frühstück: Haferflocken mit Quark und Obst (390 kcal, 19g Protein)</li>
        <li>Mittagessen: Tofu mit Kohl und Sahnesoße und Kartoffeln (650 kcal, 24g Protein)</li>
        <li>Abendessen: Hummus Wraps mit Chicken und Sour Cream (560 kcal, 35g Protein)</li>
        <li>Offene Kalorien: 200 kcal</li>
        <li>Gesamt: 1800 kcal, 78g Protein</li>
        </ul>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-2">Tag 2, 4, 6</h3>
        <ul className="list-disc pl-5">
        <li>Frühstück: Tofu Scramble (300 kcal, 17g Protein)</li>
        <li>Mittagessen: Curry mit Kichererbsen-Tomatensoße und Reis (530 kcal, 14g Protein)</li>
        <li>Abendessen: Ofengemüse mit Dip und Like Chicken (570 kcal, 25g Protein)</li>
        <li>Offene Kalorien: 200 kcal</li>
        <li>Gesamt: 1800 kcal, 56g Protein</li>
        </ul>
        </div>
        </div>
        </div>


        <div>
        <h2 className="text-2xl font-semibold mb-4">Einkaufsliste für beide Essenspläne (6 Tage)</h2>
        <div className="bg-white p-4 rounded-lg shadow-lg">
        <ul className="list-none">
        <li>
        <strong>Frühstück:</strong>
        <ul className="list-none pl-5">
        <li>
        <div className="flex justify-between items-center">
        <span>Tofu: 1800g</span>
        <input type="checkbox" id="tofu" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Gemüse (für Scramble): 1200g</span>
        <input type="checkbox" id="gemuese_fuer_scramble" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Öl: 180ml</span>
        <input type="checkbox" id="oel_fruehstueck" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Haferflocken: 600g</span>
        <input type="checkbox" id="haferflocken" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Quark: 1200g</span>
        <input type="checkbox" id="quark" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Obst: 1800g</span>
        <input type="checkbox" id="obst" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Agavendicksaft: 120g</span>
        <input type="checkbox" id="agavendicksaft" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        </ul>
        </li>
        <li>
        <strong>Mittagessen:</strong>
        <ul className="list-none pl-5">
        <li>
        <div className="flex justify-between items-center">
        <span>Kichererbsen: 1200g</span>
        <input type="checkbox" id="kichererbsen" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Tomatensoße: 2400g</span>
        <input type="checkbox" id="tomatensosse" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Reis: 1800g</span>
        <input type="checkbox" id="reis" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Öl: 180ml</span>
        <input type="checkbox" id="oel_mittagessen" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Kohl: 2400g</span>
        <input type="checkbox" id="kohl" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Sahnesoße: 1200ml</span>
        <input type="checkbox" id="sahnesosse" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Kartoffeln: 2400g</span>
        <input type="checkbox" id="kartoffeln" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        </ul>
        </li>
        <li>
        <strong>Abendessen:</strong>
        <ul className="list-none pl-5">
        <li>
        <div className="flex justify-between items-center">
        <span>Ofengemüse: 3600g</span>
        <input type="checkbox" id="ofengemuese" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Öl: 360ml</span>
        <input type="checkbox" id="oel_abendessen" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Like Chicken: 1800g</span>
        <input type="checkbox" id="like_chicken" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Dip: 1200g</span>
        <input type="checkbox" id="dip" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Wraps: 12 Stück</span>
        <input type="checkbox" id="wraps" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Hummus: 600g</span>
        <input type="checkbox" id="hummus" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Chicken: 1200g</span>
        <input type="checkbox" id="chicken" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Sour Cream: 360g</span>
        <input type="checkbox" id="sour_cream" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        <li>
        <div className="flex justify-between items-center">
        <span>Gemüse (für Wraps): 1200g</span>
        <input type="checkbox" id="gemuese_fuer_wraps" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        </ul>
        </li>
        <li>
        <strong>Snacks:</strong>
        <ul className="list-none pl-5">
        <li>
        <div className="flex justify-between items-center">
        <span>Nüsse, Obst, Joghurt, Proteinriegel (Mengen variieren je nach Präferenz und Kalorienbedarf)</span>
        <input type="checkbox" id="snacks" onChange={handleClick} className="ml-2 scale-150" />
        </div>
        </li>
        </ul>
        </li>
        </ul>
        </div>
        </div>

        </div>
    );


    function handleClick(e) {
        const id = e.target.attributes.id.nodeValue;
        localStorage.setItem(id, e.target.checked)
        if (e.target.checked) {
            setCheckedItems(items => [...items, id])
        } else {
            setCheckedItems(items => {
                console.log(items.filter(item => item !== id));
                console.log(id);
                return items.filter(item => item !== id)
            })
        }

        console.log(`click on ${id} with state ${e.target.checked}`)
    }
}

export default App;
